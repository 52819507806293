import React from 'react';
import Scene from './Scene';

export default function App() {
  return (
    <div className="relative">
      <div className="absolute h-screen w-screen pt-48 md:pt-0">
        <Scene />
      </div>

      <div className="absolute m-8">
        <div className="text-white text-lg font-bold tracking-[0.25rem] font-nunito text-left flex flex-col space-y-4">
          <a
            target="_blank"
            href="https://open.spotify.com/artist/38MaLQXVGscP2hwlflox8o?si=85cf84803bd649cd"
            rel="noreferrer"
            className="hover:tracking-[0.5rem] hover:text-blue-600 transition-all duration-500 hover:duration-150"
          >
            SPOTIFY
          </a>
          <a
            target="_blank"
            href="https://music.apple.com/us/artist/eternal-8lue/1723314792"
            rel="noreferrer"
            className="hover:tracking-[0.5rem] hover:text-blue-600 transition-all duration-500 hover:duration-150"
          >
            APPLE
          </a>
          <a
            target="_blank"
            href="https://soundcloud.com/eternal8lue"
            rel="noreferrer"
            className="hover:tracking-[0.5rem] hover:text-blue-600 transition-all duration-500 hover:duration-150"
          >
            SOUNDCLOUD
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/eternal8lue"
            rel="noreferrer"
            className="hover:tracking-[0.5rem] hover:text-blue-600 transition-all duration-500 hover:duration-150"
          >
            INSTAGRAM
          </a>
          <a
            target="_blank"
            href="https://www.tiktok.com/@eternal8lue"
            rel="noreferrer"
            className="hover:tracking-[0.5rem] hover:text-blue-600 transition-all duration-500 hover:duration-150"
          >
            TIKTOK
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UC3IZzjT81cMvK4cI8ijwWgg"
            rel="noreferrer"
            className="hover:tracking-[0.5rem] hover:text-blue-600 transition-all duration-500 hover:duration-150"
          >
            YOUTUBE
          </a>
        </div>
      </div>
    </div>
  );
}
