// @ts-nocheck

import * as THREE from 'three';
import { useState, useRef, Suspense, useMemo } from 'react';
import {
  Canvas,
  useThree,
  useFrame,
  useLoader,
  extend,
} from '@react-three/fiber';
import {
  Reflector,
  CameraShake,
  OrbitControls,
  useTexture,
  MeshReflectorMaterial,
  useGLTF,
  MeshDistortMaterial,
  MeshWobbleMaterial,
  CameraControls,
  MeshTransmissionMaterial,
} from '@react-three/drei';
import { KernelSize } from 'postprocessing';
import { EffectComposer, Bloom } from '@react-three/postprocessing';

const BLUE = new THREE.Color('rgb(12, 65, 179)');

function Ground(props) {
  const [floor, normal] = useTexture([
    '/CliffVolcanic_albedo.jpg',
    '/CliffVolcanic_normal.jpg',
  ]);
  return (
    <mesh rotation={[-Math.PI / 2, 0, Math.PI / 2]} position-y={0} scale={30}>
      <planeGeometry />
      <MeshReflectorMaterial
        resolution={1024}
        args={[8, 8]}
        color="#f0f0f0"
        metalness={0}
        roughnessMap={floor}
        normalMap={normal}
        normalScale={[2, 2]}
        mirror={1}
        blur={[500, 100]}
        mixBlur={12}
        mixStrength={1.5}
      />
    </mesh>
  );
}

function Infinity() {
  const { nodes, materials } = useGLTF('/infinity2.glb');

  const [intensity, setIntensity] = useState(0)

  useFrame(() => {
    setIntensity(Math.abs(Math.sin(Date.now() / 3000)) * 2 + 1.4); 
  });

  return (
    <mesh
      position={[0, 2, 0]}
      rotation={[Math.PI / 2, 0, 0]}
      scale={0.1}
      geometry={nodes.infinity02.geometry}
    >
      {/* <MeshTransmissionMaterial transmissionSampler /> */}
      <meshPhongMaterial
        color={BLUE}
        emissive={BLUE}
        emissiveIntensity={intensity}
      />
      {/* <MeshWobbleMaterial factor={0.1} speed={1} /> */}
      {/* <MeshDistortMaterial distort={0.5} speed={2} /> */}
    </mesh>
  );
}

export default function Scene() {
  return (
    <Canvas dpr={[1, 1.5]} camera={{ position: [10, 2, 10], fov: 60 }}>
      <fog attach="fog" args={['black', 0, 90]} />
      <CameraControls
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2.1}
        minAzimuthAngle={-Math.PI / 2}
        maxAzimuthAngle={Math.PI / 2}
      />

      <color attach="background" args={['black']} />
      <ambientLight />

      <Infinity />

      <Suspense fallback={null}>
        <Ground />
        <EffectComposer multisampling={8}>
          <Bloom
            kernelSize={3}
            luminanceThreshold={0}
            luminanceSmoothing={0.4}
            intensity={0.6}
          />
          <Bloom
            kernelSize={KernelSize.HUGE}
            luminanceThreshold={0}
            luminanceSmoothing={0}
            intensity={0.9}
          />
        </EffectComposer>
      </Suspense>
    </Canvas>
  );
}
